import React from "react";
import { Link } from "gatsby";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { FormikControl } from "../Control";

import WhiteArrow from "../../../assets/images/svg/icons/arrows/arrow-w.svg";


const PrivacyForm = ({
  caseFormTitle
}) => {
  const twilioMailFunctionURL = 'https://firebrick-porpoise-4117.twil.io/knd-case-privacy';

  const initialValues = {
    njsdnfjsdhf: '',
    ejbakdshbf: '',
    pisdhfksdjf: '',
    sshkjsbdfkk: false,
    name: '',
    email: '',
    akhbsdjhfb: false
  };

  const validationSchema = Yup.object({
    njsdnfjsdhf: Yup.string()
      .required('Required'),
    ejbakdshbf: Yup.string()
      .required('Required')
      .email('Invalid email address.'),
    pisdhfksdjf: Yup.string(),
    sshkjsbdfkk: Yup.boolean(),
    name: Yup.string(),
    email: Yup.string(),
    akhbsdjhfb: Yup.boolean()
      .required('Required')
      .oneOf([true], 'You must accept the Terms of Use and Privacy Policy.')
  });

  const onSubmit = async (e, { resetForm, setStatus }) => {
    const {
      njsdnfjsdhf,
      ejbakdshbf,
      pisdhfksdjf,
      sshkjsbdfkk,
      name,
      email,
      akhbsdjhfb
    } = e;

    const response = await fetch(twilioMailFunctionURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: new URLSearchParams({
        caseFormTitle,
        njsdnfjsdhf,
        ejbakdshbf,
        pisdhfksdjf,
        sshkjsbdfkk,
        name,
        email,
        akhbsdjhfb
      }),
    });

    resetForm();

    if (response.status === 200) {
      setStatus({
        success: true
      });
    } else {
      setStatus({
        success: false
      });
    }
  };
  
  return (
    <section className="container mx-auto my-16 px-6 lg:px-28 2xl:px-80">
      <Formik
        initialValues={ initialValues }
        validationSchema={ validationSchema }
        onSubmit={ onSubmit }
      >
        { props => (
          <Form>
            <div className="flex flex-col md:flex-row md:justify-between space-y-4 md:space-y-0 md:space-x-8 text-lg">
              <div className="flex flex-col w-full space-y-4">
                <FormikControl
                  control="input"
                  label="Name"
                  name="njsdnfjsdhf"
                  type="text"
                  placeholder="Enter your first and last name"
                />
                <FormikControl
                  control="input"
                  label="Phone"
                  name="pisdhfksdjf"
                  type="tel"
                  placeholder="Enter your phone number"
                />
              </div>
              <div className="flex flex-col w-full space-y-4">
                <FormikControl
                  control="input"
                  label="Email"
                  name="ejbakdshbf"
                  type="email"
                  placeholder="Enter your email address"
                />
              </div>
            </div>
            <div className="my-4">
              <FormikControl
                control="boolean"
                label={ <>I accept the <Link className="underline" to="/legal/terms-of-use">Terms of Use</Link> and <Link className="underline" to="/legal/privacy-policy">Privacy Policy</Link>.</> }
                name="akhbsdjhfb"
              />
            </div>
            <div>
              <FormikControl
                control="honeypot"
                label="Name"
                name="name"
                autoComplete="off"
                placeholder="Enter your first and last name"
              />
            </div>
            <div>
              <FormikControl
                control="honeypot"
                label="Email"
                name="email"
                autoComplete="off"
                placeholder="Enter your email address"
              />
            </div>
            <button type="submit" className="w-32 h-12 mt-2 bg-blue-dark text-white">
              { props.isSubmitting ? 'Sending...' : 
                (
                  <div className="flex flex-row justify-center items-center">
                    <span className="flex-inital pr-2 font-thin">Submit</span>
                    <span className="flex-inital">
                      <WhiteArrow className="w-6" />
                    </span>
                  </div>
                )
              }
            </button>
            { props.status &&
              <div>
                { props.status.success &&
                  <div className="my-4 text-green-700 text-lg font-thin">
                    <span>Thank you for your submission, we'll update you as soon as we can.</span>
                  </div>
                }
                { !props.status.success &&
                  <div className="my-4 text-red-700 text-lg font-thin">
                    <span>
                      Something went wrong. Please contact us directly at <a className="underline" href="mailto:contact@knd.law">contact@knd.law</a>.
                    </span>
                  </div>
                }
              </div>
            }
          </Form>
        )}
      </Formik>
    </section>
  )
}

export default PrivacyForm;