import React from "react";


const Post = ({
  body,
  date,
  template
}) => {
  return (
    <article className="markdown-post">
      <div dangerouslySetInnerHTML={{ __html: body }} />
      <div className="italic">
        { template === "legal" &&
          <span>Last Updated: { date }</span>
        }
      </div>
    </article>
  )
}

export default Post;