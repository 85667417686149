import React from "react";
import { graphql } from "gatsby";

import CallToAction from "../components/CallToAction/CallToAction";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Layout from "../components/Layout/Layout";
import MarkdownHero from "../components/Hero/MarkdownHero";
import Post from "../components/Post/Post";
import PrivacyForm from "../components/Form/Cases/PrivacyForm";
import SecuritiesForm from "../components/Form/Cases/SecuritiesForm";
import SecuritiesSimpleForm from "../components/Form/Cases/SecuritiesSimpleForm";
import SingleImage from "../components/SingleMedia/SingleImage";


const CaseTemplate = ({
  data
}) => {
  const {
    markdownRemark: {
      frontmatter: {
        date,
        description,
        location,
        title,
        template,
        caseType,
        settled,
        settledDate,
        featuredImage: {
          childImageSharp: {
            fluid
          }
        },
        altText
      },
      html
    }
  } = data;

  return (
    <Layout
      title={ title }
      description={ description }
    >
      <Header
        colorScheme="light"
      />

      <MarkdownHero
        title={ title }
        template={ template }
        location={ location }
        date={ date }
        settled={ settled }
        settledDate={ settledDate }
        colorScheme="light"
      />

      <SingleImage
        image={ fluid }
        imageAlignment="left"
        altText={ altText }
        colorScheme="light"
      />

      <Post
        body={ html }
        date={ date }
        template={ template }
      />

      { caseType === 'securities' &&
        <SecuritiesForm
          caseFormTitle={ title }
        />
      }
      { caseType === 'securities-simple' &&
        <SecuritiesSimpleForm
          caseFormTitle={ title }
        />
      }
      { caseType === 'privacy' &&
        <PrivacyForm
          caseFormTitle={ title }
        />
      }
      { caseType === 'none' &&
        <></>
      }

      <CallToAction />

      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        description
        location
        title
        template
        caseType
        settled
        settledDate(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        altText
      }
    }
  }
`;

export default CaseTemplate;