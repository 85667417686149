import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";


const MarkdownHero = ({
  title,
  subtitle,
  author,
  authorLink,
  position,
  phone,
  email,
  image,
  altText,
  template,
  location,
  date,
  settled,
  settledDate,
  contactInfo,
  colorScheme
}) => {
  let style = {};

  if (colorScheme === "dark") {
    style = {
      container: "pt-28 lg:pt-32 bg-blue-dark text-white",
      title: "text-3xl md:text-4xl lg:text-5xl font-thin tracking-wide",
    }
  } else {
    style = {
      container: "pt-28 lg:pt-32 bg-white",
      title: "text-3xl md:text-4xl lg:text-5xl font-thin tracking-wide text-blue-dark",
    }
  }
  
  return (
    <section className="w-screen">
      <div className={ style.container }>
        <div className="container mx-auto px-6 lg:px-28 2xl:px-80 pt-4 lg:pt-24">
          { template === "blog" &&
            <div>
              <h1 className={ style.title }>
                <span className="block">{ title }</span>
              </h1>
              <p className="my-4 font-thin lg:text-lg">
                { date } &mdash; <span className="underline"><Link to={ authorLink }>{ author }</Link></span>
              </p>
              <p className="my-8 font-thin lg:text-lg">
                { subtitle }
              </p>
            </div>
          }
          { template === "career" &&
            <div>
              <h1 className={ style.title }>
                <span className="block">{ title }</span>
              </h1>
              <div className="my-8 font-thin lg:text-lg">
                <span className="block">{ location }</span>
              </div>
            </div>
          }
          { template === "case" &&
            <div>
              <h1 className={ style.title }>
                <span className="block">{ title }</span>
              </h1>
              <div className="my-8 font-thin lg:text-lg">
                <span className="block">{ location }</span>
                <span className="block italic">Commencement Date: { date }</span>
                { settled &&
                  <span className="block italic">Date of Court Approval of Settlement: { settledDate }</span>
                }
              </div>
            </div>
          }
          { template === "legal" &&
            <div>
              <h1 className={ style.title }>
                <span className="block">{ title }</span>
              </h1>
            </div>
          }
          { template === "partner" &&
            <div className="lg:flex lg:flex-row lg:space-x-8">
              <div className="flex-1">
                <Img
                  fluid={ image }
                  alt={ altText }
                />
              </div>
              <div className="flex-1">
                <h1 className={ style.title }>
                  <span className="block">{ title }</span>
                </h1>
                <div className="my-8 font-thin lg:text-lg">
                  <span className="block">{ position }</span>
                  <span className="block">
                    <a href={ "tel:" + phone }>
                      { phone.slice(0, 2) + " " + phone.slice(2, 5) + " " + phone.slice(5, 8) + " " + phone.slice(8, 12) + " ext. " + phone.slice(13, 14) }
                    </a>
                  </span>
                  <span className="block"><a href={ "mailto:" + email }>{ email }</a></span>
                </div>
              </div>
            </div>
          }
          { template === "practice-area" &&
            <div>
              <h1 className={ style.title }>
                <span className="block">{ title }</span>
              </h1>
              <p className="my-8 font-thin lg:text-lg">
                { subtitle }
              </p>
              { contactInfo && 
                <div className="pt-8 2xl:pt-32 tracking-wide">
                  <span className="block"><a href="tel:+14165373529">+1 416 537 3529</a></span>
                  <span className="block"><a href="mailto:contact@knd.law">contact@knd.law</a></span>
                </div>
              }
            </div>
          }
          { template === "settlement" &&
            <div>
              <h1 className={ style.title }>
                <span className="block">{ title }</span>
              </h1>
              <div className="my-8 font-thin lg:text-lg">
                <span className="block">{ location }</span>
                <span className="block italic">Commencement Date: { date }</span>
              </div>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default MarkdownHero;
