import React from "react";
import Img from "gatsby-image";


const SingleImage = ({
  image,
  imageAlignment,
  fullWidth,
  altText,
  colorScheme
}) => {
  let style = {
    alignment: {},
    colorScheme: {},
  };

  if (imageAlignment === "left") {
    style.alignment = {
      imageContainer: "lg:mb-16 lg:float-left hero-image-width",
    };
  } else if (imageAlignment === "right") {
    style.alignment = {
      imageContainer: "lg:mb-16 lg:float-right hero-image-width",
    };
  } else if (imageAlignment === "center") {
    if (fullWidth) {
      style.alignment = {
        imageContainer: "w-full lg:mb-16",
      };
    } else {
      style.alignment = {
        imageContainer: "container mx-auto lg:mb-16 lg:px-28",
      };
    }
  }

  if (colorScheme === "dark") {
    style.colorScheme = {
      background: "w-full lg:absolute lg:top-0 lg:h-48 bg-blue-dark z-n20",
    }
  } else if (colorScheme === "light") {
    style.colorScheme = {
      background: "w-full lg:absolute lg:top-0 lg:h-48 bg-white z-n20",
    };
  }
  
  return (
    <section className="relative w-full lg:pt-16">
      <div className={ style.colorScheme.background }></div>
      <div className={ style.alignment.imageContainer }>
        <Img
          fluid={ image }
          alt={ altText }
        />
      </div>
    </section>
  )
}

export default SingleImage;